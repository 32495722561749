.merge-clients {
	max-width: 800px;
	margin: 0 auto;
	padding: 2rem;

	&__form {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&__field {
		width: 100%;
	}

	&__error {
		color: #dc3545;
		margin-top: 1rem;
		padding: 0.5rem;
		border-radius: 4px;
		background-color: rgba(220, 53, 69, 0.1);
	}

	&__success {
		color: #28a745;
		margin-top: 1rem;
		padding: 0.5rem;
		border-radius: 4px;
		background-color: rgba(40, 167, 69, 0.1);
	}

	&__preview {
		h2 {
			margin-bottom: 1.5rem;
		}
	}

	&__actions {
		display: flex;
		gap: 1rem;
		margin-top: 1.5rem;
	}

	.preview-table {
		margin-top: 1rem;
	}
}
